// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aptfi-primary: mat.define-palette(mat.$blue-gray-palette, 700);
$aptfi-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aptfi-warn: mat.define-palette(mat.$red-palette);

$aptfi-typography: mat.define-legacy-typography-config(
  // Specify "Manrope" as the default font family for all levels.
  $font-family: "Manrope"
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$aptfi-theme: mat.define-light-theme(
  (
    color: (
      primary: $aptfi-primary,
      accent: $aptfi-accent,
      warn: $aptfi-warn,
    ),
    typography: $aptfi-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aptfi-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Manrope", "Helvetica Neue", sans-serif;
}


.mat-mdc-snack-bar-container {
  &.snackbar-warn {
    --mdc-snackbar-container-color: #ea5455;
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.snackbar-success {
  background-color: #8ecf54;
  .mat-simple-snackbar {
    span {
      color: #333;
    }
  }
}

.nav-accordion .mat-expansion-panel-body {
  padding: 0;
}
.nav-accordion .mat-expansion-indicator::after {
  color: #fff;
}

.mat-step-icon-state-done {
  background-color: #8ecf54 !important;
  &.mat-step-icon-selected {
    background-color: #8ecf54 !important;
  }
}

.mat-step-icon-selected {
  // background-color: #f5bd00 !important;
}

.mat-card-header-text {
  margin: 0 !important;
}

.pills {
  padding: 2px 10px;
  background-color: #aaa;
  color: #fff !important;
  border-radius: 5px;
  font-size: 11px;
  &.warn {
    background-color: #ea5455;
  }
  &.success {
    background-color: #8ecf54;
  }
  &.yellow {
    background-color: #f7c601;
    color: #ddd;
  }
}
